import { IMG_ENDINGS } from "~/shared/constants";
import { capitalizeFirstLetter, copyTextToClipboard } from "~/shared/utils";
import {
  InspirationBoard,
  InspirationBoardAsset,
  InspirationBoardAssetResponse,
  InspirationBoardResponse,
} from "~/types/InspirationBoard";
import { AdFormat } from "~/types/shared";

export const useInspirationBoard = () => {
  const getMappedInspirationBoards = (
    boards: Array<InspirationBoardResponse>,
  ): Array<InspirationBoard> => {
    const mappedBoards = boards.map((board) => {
      return {
        id: board.id,
        uuid: board.uuid,
        title: board.title,
        description: board.description,
        createdAt: board.createdAt,
        createdBy: board.createdBy,
        clientId: board.clientId,
        folderId: board.folderId,
        fileSortOrder: board.fileSortOrder,
        assets: getMappedInspirationBoardAssets(board.assets),
        workspaceId: board.workspaceId,
      };
    });

    mappedBoards.sort((a, b) => a.id - b.id);

    return mappedBoards;
  };

  const getMappedInspirationBoardAssets = (
    assets: Array<InspirationBoardAssetResponse>,
  ): Array<InspirationBoardAsset> => {
    const mappedAssets = assets.map((asset) => {
      return {
        ...asset,
        displayCta: getDisplayCta(asset.cta),
        isRunning:
          asset.adDeliveryStopTime == null || asset.adDeliveryStopTime === "",
      };
    });

    // Newest asset first
    mappedAssets.sort((a, b) => b.id - a.id);

    return mappedAssets;
  };

  const getDisplayCta = (cta: string) => {
    return cta
      .split("_")
      .flatMap((c) => c.split("-"))
      .flatMap((c) => c.split(" "))
      .map((c) => capitalizeFirstLetter(c))
      .join(" ");
  };

  const emptyAssetBrandName = "##empty";

  const allAssetsNotEmpty = (assets: Array<InspirationBoardAsset>) => {
    return assets.every((a) => !isEmptyAsset(a));
  };

  const isEmptyAsset = (asset: InspirationBoardAsset) => {
    return asset.brandName.startsWith(emptyAssetBrandName);
  };

  const getInspirationBoardDetailPage = (boardUuid: string) => {
    return "/inspiration-board/detail/" + boardUuid;
  };

  const getInspirationBoardDuplicatePage = (boardId: number) => {
    return "/inspiration-board?boardId=" + boardId + "&mode=duplicate";
  };

  const createBoard = async (dto: {
    clientId: number;
    folderId: number | null;
  }) => {
    const notificationStore = useNotificationStore();
    const store = useInspirationBoardStore();

    const boardIdOrError = await store.createBoard({
      title: null,
      clientId: dto.clientId,
      description: null,
      folderId: dto.folderId,
    });

    if (boardIdOrError == null) {
      notificationStore.notify({
        type: "error",
        message: "Something went wrong",
      });
      return;
    }

    if (typeof boardIdOrError === "string") {
      notificationStore.notify({
        type: "error",
        message: boardIdOrError,
      });
      return;
    }

    const { uuid } = boardIdOrError;

    const router = useRouter();
    router.push(getInspirationBoardDetailPage(uuid));
  };

  const copyBoardShareLink = (boardUuid: string) => {
    const config = useRuntimeConfig();
    const { notify } = useNotifications();
    const { track, AnalyticsEvents } = useSegment();
    const shareLink =
      config.public.baseUrl + "/inspiration-board/share/board/" + boardUuid;
    copyTextToClipboard(shareLink);
    notify(null, "Link copied");
    track(AnalyticsEvents.SHARE_INSPIRATION_BOARD);
  };

  const copyAssetShareLink = (asset: InspirationBoardAsset) => {
    const config = useRuntimeConfig();
    const { notify } = useNotifications();
    const shareLink = asset.customUpload
      ? asset.mediaUrl
      : config.public.baseUrl + "/inspiration-board/share/asset/" + asset.uuid;
    copyTextToClipboard(shareLink);
    notify(null, "Link copied");
    const { track, AnalyticsEvents } = useSegment();
    track(AnalyticsEvents.SHARE_INSPIRATION_BOARD_ASSET);
  };

  const deleteBoard = async (boardId: number) => {
    const router = useRouter();
    const { notify } = useNotifications();
    const store = useInspirationBoardStore();

    const confirmed = confirm(
      "Are you sure you want to delete this board? This action cannot be undone.",
    );

    if (confirmed) {
      const errorMaybe = await store.deleteBoard(boardId);
      notify(errorMaybe, "Board deleted successfully.");
      await router.push("/");
    }
  };

  const deleteBoardAsset = async (assetId: number) => {
    const { notify } = useNotifications();
    const store = useInspirationBoardStore();

    const confirmed = confirm(
      "Are you sure you want to delete this asset? This action cannot be undone.",
    );

    if (confirmed) {
      const errorMaybe = await store.deleteBoardAsset(assetId);
      notify(errorMaybe, "Asset deleted successfully.");
    }
  };

  const getSavedBeforeStr = (createdAtStr: string) => {
    const createdAt = new Date(createdAtStr);
    const now = new Date();
    const diff = now.getTime() - createdAt.getTime();
    const diffHours = Math.floor(diff / (1000 * 3600));
    const diffDays = Math.floor(diff / (1000 * 3600 * 24));
    const diffWeeks = Math.floor(diff / (1000 * 3600 * 24 * 7));
    if (diffHours < 1) {
      return `Saved a few minutes ago`;
    } else if (diffHours < 24) {
      return `Saved ${diffHours} hours ago`;
    } else if (diffDays < 7) {
      return `Saved ${diffDays} days ago`;
    } else {
      return `Saved ${diffWeeks} weeks ago`;
    }
  };

  const fileToAdFormat = (file: File): AdFormat => {
    const isImg = IMG_ENDINGS.test(file.type);
    return isImg ? AdFormat.IMAGE : AdFormat.VIDEO;
  };

  return {
    getMappedInspirationBoards,
    getMappedInspirationBoardAssets,
    isEmptyAsset,
    allAssetsNotEmpty,
    getInspirationBoardDetailPage,
    getInspirationBoardDuplicatePage,
    createBoard,
    copyBoardShareLink,
    copyAssetShareLink,
    deleteBoard,
    getSavedBeforeStr,
    deleteBoardAsset,
    getDisplayCta,
    fileToAdFormat,
  };
};
